import { Environment } from './utils/environment-interface';

const partnerPortal = '#{partner.url}#';
const portal = '#{portal.url}#';

// prettier-ignore
export const environment: Environment = {
  id: '#{apiEnvironment}#',
  version: '#{Build.BuildNumber}#',
  webHooksAPI: '#{webHooksApi}#',
  homepage: 'https://www.pushdoctor.co.uk',
  partner: {
    api: '#{partner.api}#',
    admin: partnerPortal + '/portal/nhs/?/invite',
    cn: partnerPortal + '/portal/admin/?/partners'
  },
  portal: {
    api: '#{portal.api}#',
    url: portal,
    signin: portal + '/auth/signin',
    apiWithJWT: '#{portal.apiWithJWT}#',
  },
  pdrportal: {
    url: '#{opsPortal.url}#'
  },
  production: true,
  appInsights: {
    config: {
      instrumentationKey: '#{appInsights.key}#'
    }
  },
  google: {
    portalGtm: '#{google.portalGtm}#',
  },
};
